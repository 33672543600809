import AccountService from "@/services/AccountService"

export const checkAudioAdvertisingAvailability = async ({ commit }, accountKey) => {
  const audioAdvertisingAvailability = await AccountService.accountAudioAdvertisingAvailability(accountKey)

  commit("setAudioAdvertisingAvailability", audioAdvertisingAvailability.available)
}

export const resetAudioAdvertisingAvailability = async ({ commit }) => {
  try {
    commit("reset")
  } catch (e) {
    console.log(e)
    throw e
  }
}
