import { initialState } from "./state"

export const setAudioAdvertisingAvailability = (state, payload) => {
  state.audioAdvertisingAvailability = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}
