import accountApi from "@/api/account.api";

export const searchAccounts = async ({ query, limit = 24, offset = 0 }) => {
  return await accountApi.search(query, limit, offset);
};

export const getAccountList = async ({pageNumber, pageSize, sortBy, sortDesc, search }) => {
  return await accountApi.list(pageNumber, pageSize, sortBy, sortDesc, search);
};

export const getAccount = async (id) => {
  return await accountApi.get(id);
};

export const addAccount = async (data) => {
  return await accountApi.add(data);
};

export const createAccount = async (data) => {
  return await accountApi.store(data);
};

export const updateAccount = async (data) => {
  return await accountApi.update(data);
};

export const suspendAccount = async (id) => {
  return await accountApi.suspend(id);
};

export const reactivateAccount = async (id) => {
  return await accountApi.reactivate(id);
};

export const deleteAccount = async (id) => {
  return await accountApi.destroy(id);
};

export const getAccountStations = async (id, limit, offset) => {
  return await accountApi.getStations(id, limit, offset);
};

export const getAccountDeletedStations = async (id, limit, offset) => {
  return await accountApi.getStations(id, limit, offset,true);
};

export const getAccountUndeleteStation = async (id, stationKey) => {
  return await accountApi.getUndeleteStation(id, stationKey);
};

export const updateAccountUndeleteStation = async (id, stationKey) => {
  return await accountApi.updateUndeleteStation(id, stationKey);
};

export const getAccountCtlStations = async (id) => {
  return await accountApi.getCtlStations(id);
};

export const getAccountCustomLimits = async (id) => {
  return await accountApi.getCustomLimits(id);
};

export const updateAccountCustomLimits = async (data) => {
  return await accountApi.updateCustomLimits(data);
};

export const createAccountAudioAdvertisingRequest = async (id, data) => {
  return await accountApi.createAudioAdvertisingRequest(id, data);
};

export const accountAudioAdvertisingAvailability = async (id, data) => {
  return await accountApi.getAudioAdvertisingAvailability(id, data);
};

export default {
  searchAccounts,
  getAccountList,
  getAccount,
  addAccount,
  createAccount,
  updateAccount,
  suspendAccount,
  reactivateAccount,
  deleteAccount,
  getAccountStations,
  getAccountDeletedStations,
  getAccountUndeleteStation,
  updateAccountUndeleteStation,
  getAccountCtlStations,
  getAccountCustomLimits,
  updateAccountCustomLimits,
  createAccountAudioAdvertisingRequest,
  accountAudioAdvertisingAvailability
};
