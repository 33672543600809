import moment from "moment/moment"

export const podcastAnalytics = (state) => {
  return state.podcastAnalytics
}

export const analyticsSelectedPodcasts = (state) => {
  return state.selectedPodcasts
}

export const analyticsPeriod = (state) => {
  return state.period
}

export const totalUniqueListeners = (state, getters) => {
  return getters.podcastAnalytics.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.UniqueListeners
  }, 0)
}

export const totalListeningTime = (state, getters) => {
  return getters.podcastAnalytics.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.TotalDuration
  }, 0)
}

export const totalListeningTimeHours = (state, getters) => {
  return getters.totalListeningTime
    ? moment
      .duration(getters.totalListeningTime, "seconds")
      .asHours()
      .toFixed(2)
    : 0;
}

export const averageListeningTimeMinutes = (state, getters) => {
  return getters.totalListeningTime && getters.totalUniqueListeners
    ? moment
      .duration(
        getters.totalListeningTime / getters.totalUniqueListeners,
        "seconds"
      )
      .asMinutes()
      .toFixed(2)
    : 0;
}
