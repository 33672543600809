import Profile from "@/store/modules/profile";
import Finance from "@/store/modules/finance";
import AccountStations from "@/store/modules/accountStations";

import Account from "@/store/modules/account";
import AccountAdvertising from "@/store/modules/accountAdvertising";
import AccountCustomLimits from "@/store/modules/accountCustomLimits";
import Station from "@/store/modules/station";
import Podcast from "@/store/modules/podcast";
import AccountSubscriptions from "@/store/modules/accountSubscriptions";
import Subscription from "@/store/modules/subscription";
import StationSubscriptions from "@/store/modules/stationSubscriptions";
import Ctl from "@/store/modules/ctl";
import Acl from "@/store/modules/acl";
import StationRelay from "@/store/modules/stationRelay";
import PortalAnalytics from "@/store/modules/portalAnalytics";
import PortalSettings from "@/store/modules/portalSettings";
import PortalChangelog from "@/store/modules/portalChangelog";
import StationAudioAds from "@/store/modules/stationAudioAds";
import Countries from "@/store/modules/countries";
import StationAutomation from "@/store/modules/stationAutomation";
import Analytics from "@/store/modules/analytics";
import StationPlayer from "@/store/modules/stationPlayer";
import Autodj from "@/store/modules/autodj";
import Library from "@/store/modules/library";


const requireModule = require.context(".", true, /index\.js$/);

const modules = {
  AccountStations,
  Account,
  AccountAdvertising,
  AccountCustomLimits,
  Station,
  Podcast,
  AccountSubscriptions,
  Subscription,
  Profile,
  Finance,
  StationSubscriptions,
  Ctl,
  Acl,
  StationRelay,
  PortalAnalytics,
  PortalSettings,
  PortalChangelog,
  StationAudioAds,
  Countries,
  StationAutomation,
  Analytics,
  StationPlayer,
  Autodj,
  Library,
};

requireModule.keys().forEach((filename) => {
  if (filename === "./index.js") {
    return;
  }

  const moduleName = filename
    .replace(/(\.\/|\/index\.js)/g, "")
    .replace(/^\w/, (c) => c.toUpperCase());

  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
