import SubscriptionService from "@/services/SubscriptionService";

export const getAccountSubscriptions = async ({ commit }, accountId) => {
  const subscriptionList = await SubscriptionService.getAccountSubscriptions(
    accountId
  );

  commit("setAccountSubscriptions", subscriptionList);

  return subscriptionList;
};

export const fetchAccountPlans = async ({ commit }, accountId) => {
  const plans = await SubscriptionService.getAccountPlans(accountId);

  commit("setAccountPlans", plans);
};
